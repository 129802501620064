import React from 'react'
import { NavLink } from 'theme-ui'

const styles = {

};

export const MenuLinks = () => {
  return (
    <>
    <NavLink
        mr={3}
        pb={3}
        sx={{
            display: 'block'
        }}
        href='/contact'
      >
        {'Contact Us'}
      </NavLink>
      <NavLink
        mr={3}
        pb={3}
        sx={{
            display: 'block'
        }}
        href='https://app.simplereviewmanagement.com/login'
      >
        {'Log in'}
      </NavLink>
    </>
  )
}
