import React from 'react'
import { Box, Text, NavLink } from 'theme-ui'
import { StaticQuery, graphql } from 'gatsby';
import ContentImages from '@solid-ui-components/ContentImages'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { MenuLinks } from './MenuLinks'

export const Header = ({menuClick}) => {
  return (
    <StaticQuery
      query={graphql`
        query supportHeaderBlockContent {
          allBlockContent(
            filter: { page: { in: ["site-shared"] } }
          ) {
            nodes {
              ...BlockContent
            }
          }
        }
      `}
      render={data => {
        const { allBlockContent } = data
        const content = normalizeBlockContentNodes(allBlockContent?.nodes)
        const { images } = content['header'];

        return (
          <Box
            pt={3}
            pb={3}
            pl={3}
            pr={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              variant: 'styles.header',
            }}
          >
            <NavLink
              href="/"
            >
              <ContentImages
                sx={{
                  width: '100px'
                }}
                content={{ images }}
                imageEffect='fadeIn'
              />
            </NavLink>
            <Text
                ml={4}
                sx={{
                  fontSize: '1.5rem',
                }}
              >
                Help Center
              </Text>
            <Box sx={{ flex: '1 1 auto' }} />
            {/* <Field
              placeholder='Search'
              p={2}
            /> */}
            <Box
              sx={{
                display: ['none', 'none', 'flex']
              }}
            >
              <MenuLinks />
            </Box>
            <NavLink
              href="#"
              sx={{
                display: ['flex', 'flex', 'none']
              }}
              onClick={(evt) => {
                evt.preventDefault();
                menuClick();
              }}  
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentcolor"
                viewBox="0 0 24 24"
                sx={{
                  display: 'block',
                  margin: 0,
                }}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
              </svg>
            </NavLink>
          </Box>
        )
      }}
    />
  )
}
