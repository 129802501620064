import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Send Review Requests" prev={{
  name: 'Account Dashboard Intro',
  path: '/account-dashboard-intro'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This article assumes you have completed the `}<a parentName="p" {...{
        "href": "/help-center/account-setup/"
      }}>{`account setup`}</a>{` process. If you have not, start there.`}</p>
    <p>{`There are multiple ways to Send Review Requests with Simple RM. Which method you use depends on the setup of your account.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
Users with QuickBooks Online connected have additional options for triggering a review request. They can enable the <Link href="/help-center/auto-send-setting/" mdxType="Link">Auto Send Setting</Link> so review requests are sent automatically or use a <Link href="/help-center/triggering-with-a-custom-field" mdxType="Link">custom field to trigger</Link> them for specific customers from QuickBooks Online.
    </Message>
    <p>{`On the `}<a target="_blank" href='https://app.simplereviewmanagement.com/customers'>{`customers page`}</a>{` find the customer you would like to send a review request to.`}</p>
    <p>{`If you do not have any customers in your account follow `}<a parentName="p" {...{
        "href": "/help-center/adding-customers"
      }}>{`this article`}</a>{`.`}</p>
    <p>{`Click on the customers row to expand it. Once expanded find the “Send Review Request” button.`}</p>
    <p>{`The button may not be clickable. There are a few reasons why:`}</p>
    <ul>
      <li parentName="ul">{`The customer could be missing a contact method (phone number or email address)`}</li>
      <li parentName="ul">{`Your account could be set up to require the customer to have a first name set to send them a review request and this customer is missing a first name.`}</li>
      <li parentName="ul">{`The customer could be on the “No contact list”.`}</li>
      <li parentName="ul">{`The customer could already have a review request in progress.`}</li>
    </ul>
    <p>{`If you are able to do so, click the button.`}</p>
    <p>{`A popup will appear. In the popup confirm you would like to send the customer a review request. `}</p>
    <p>{`The customer’s status will now transition to “In progress”. This status means the customer has entered the `}<a parentName="p" {...{
        "href": "/help-center/review-request-flow"
      }}>{`review request flow`}</a>{`.`}</p>
    <p>{`You will be able to see any request sent to this customer as well as any action the customer takes on the `}<a target="_blank" href='https://app.simplereviewmanagement.com/activity'>{`activity page`}</a>{`.`}</p>
    <p>{`Please be aware it may be several days before the first review request is sent to a customer that is “In progress”. This is because of the `}<a parentName="p" {...{
        "href": "/help-center/smart-send-times"
      }}>{`Smart Send Time feature`}</a>{`.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
You can select multiple customers at the same time. Doing so will reveal a bulk action menu at the top right of the page. One of the options in the menu is to trigger a review request.
    </Message>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      