import React, { useState } from 'react'
import { Container, Card, Flex, Box, NavLink } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Divider from '@solid-ui-components/Divider'
import { Footer } from '../../components/Footer'
import { Header } from './Header'
import { Nav } from './Nav'

const styles = {
  header: {
    marginTop: '0px',
  }
};

export const PageStructure = ({layoutProps, title, children, next, prev}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Layout {...layoutProps}>
      <Header menuClick={() => {
        setMenuOpen(!menuOpen);
      }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'column', 'row']
        }}
      >
        <Box
          sx={{
            display: ['block', 'block', 'none']
          }}
        >
          {menuOpen && <Nav />}
        </Box>
        <Box
          sx={{
            minWidth: '250px',
            display: ['none', 'none', 'block']
          }}
        >
          <Nav desktopMenu />
        </Box>
        <Container>
          <h1>{title}</h1>
          <Card>
            <Container p={4}>
              {children}
            </Container>
          </Card>
          <Flex
            sx={{
              color: '#3b82f6'
            }}
          >
            {prev && <NavLink
              p={2}
              href={`/help-center${prev.path}`}
            >
              Previous
              <Box
                sx={{
                  fontWeight: 400,
                  lineHeight: '1rem',
                }}
              >
                {prev.name}
              </Box>
            </NavLink>}
            <Box sx={{ flex: '1 1 auto'}} />
            {next && <NavLink
              p={2}
              href={`/help-center${next.path}`}
            >
              Next Article
              <Box
                sx={{
                  fontWeight: 400,
                  lineHeight: '1rem',
                }}
              >
                {next.name}
              </Box>
            </NavLink>}
          </Flex>
        </Container>
      </Box>
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}
