import React, {useState} from 'react'
import { Box, Text, NavLink } from 'theme-ui'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import { MenuLinks } from './MenuLinks'

const styles = {
  category: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '2px 2px',
    ':hover': {
      background: '#eeeeee',
    }
  },
  navItem: {
    display: 'block',
    lineHeight: '1.3rem',
    fontWeight: 100,
    padding: '10px 5px',
    ':hover': {
      background: '#eeeeee',
    }
  },
  active: {
    backgroundColor: '#39b8f621',
  }
};

export const Nav = ({desktopMenu}) => {

  const isCurrentArticleInList = (acticles) => {
    let articleInList = false;
    acticles.forEach((article) => {
      if (isCurrentArticle(article.path)) {
        articleInList = true;
      }
    })
    return articleInList;
  }

  const isCurrentArticle = (path) => {
    if (typeof window !== 'undefined') {
      return removeTrailingSlash(window.location.pathname) === `/help-center${path}`;
    }

    return false;
  }

  const removeTrailingSlash = (threString) => {
    if (threString) {
      return (threString.substr(-1) === '/') ? threString.slice(0, -1) : threString;
    }

    return null;
  }

  const quickStartArticles = [
    {
      name: 'Getting Started',
      path: '/getting-started'
    },
    {
      name: 'Account Setup',
      path: '/account-setup'
    },
    {
      name: 'Account Initialization',
      path: '/account-initialization-process'
    },
    {
      name: 'Account Dashboard Into',
      path: '/account-dashboard-intro'
    },
    {
      name: 'Send Review Requests',
      path: '/send-review-requests'
    },
  ];

  const howToArticles = [
    {
      name: 'Creating an Account',
      path: '/creating-an-account'
    },
    {
      name: 'Creating a Company',
      path: '/creating-a-company'
    },
    {
      name: 'Account Creation with QuickBooks Online',
      path: '/account-creation-with-quickbooks-online'
    },
    {
      name: 'Connect Google My Business',
      path: '/connect-google-my-business'
    },
    {
      name: 'Connect QuickBooks Online',
      path: '/connect-quickbooks-online'
    },
    {
      name: 'Request Opportunities',
      path: '/request-opportunities',
    },
    {
      name: 'Adding Customers',
      path: '/adding-customers',
    },
  ];

  const troubleshootingArticles = [
    {
      name: 'Google Review Link Not Working',
      path: '/google-review-link-not-working'
    },
  ];

  const knowledgeBaseArticles = [
    {
      name: 'About Our Pricing',
      path: '/about-our-pricing'
    },
    {
      name: 'Auto Send Setting',
      path: '/auto-send-setting'
    },
    {
      name: 'Smart Send Times',
      path: '/smart-send-times'
    },
    {
      name: 'Bulk Customer Import',
      path: '/bulk-customer-import'
    },
    {
      name: 'Customer Experience Preview',
      path: '/customer-experience-preview'
    },
    {
      name: 'Triggering With A QuickBooks Custom Field',
      path: '/triggering-with-a-custom-field'
    },
    {
      name: 'Referral Program',
      path: '/referral-program'
    },
    {
      name: 'Review Request Flow',
      path: '/review-request-flow'
    },
    {
      name: 'Review Request Templates',
      path: '/review-request-templates'
    },
    {
      name: 'Custom Review Request Email Templates',
      path: '/custom-review-request-email-templates'
    },
  ];

  const articleCategories = [
    {
      name: 'Quick Start',
      hook: useState(true),
      articles: quickStartArticles
    },
    {
      name: 'How To',
      hook: useState(isCurrentArticleInList(howToArticles)),
      articles: howToArticles
    },
    {
      name: 'Troubleshooting',
      hook: useState(isCurrentArticleInList(troubleshootingArticles)),
      articles: troubleshootingArticles
    },
    {
      name: 'Knowledge Base',
      hook: useState(isCurrentArticleInList(knowledgeBaseArticles)),
      articles: knowledgeBaseArticles
    },
  ];

  return (
    <Box
      pt={3}
      pl={3}
      sx={desktopMenu ? {
        position: 'sticky',
        top: '0px',
        height: '100vh',
        overflow: 'scroll'
      } : null}
    >
      {!desktopMenu && <MenuLinks />}
      {articleCategories.map((articleCategory) => {
        return (
          <>
            <Box
              mt={3}
              sx={styles.category}
              onClick={() => articleCategory.hook[1](!articleCategory.hook[0])}
            >
              <Text
                mr={2}
                sx={{
                  fontWeight: 600,
                }}
              >
                {articleCategory.name}
              </Text>
              {articleCategory.hook[0] ? <BiChevronUp /> : <BiChevronDown />}
            </Box>
            {articleCategory.hook[0] && articleCategory.articles.map((article) => {
              const active = isCurrentArticle(article.path);

              return (
                <NavLink
                  sx={active ? {...styles.navItem, ...styles.active} : styles.navItem}
                  href={`/help-center${article.path}`}
                >
                  {article.name}
                </NavLink>
              )
            })}
          </>
        );
      })}
    </Box>
  )
}
